.repayment_div_container {
  display: flex;
  flex-direction: column;
  height: 92%;
  margin-right:3%;
}

.repayment_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 0px;
}

.repayment_header_div {
  width: 100%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.repayment_header_div_title {
  margin: 0;
  padding: 0;
  margin-left: 1%;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.repayment_search_bar_div {
  width: 100%;
  align-self: left;
  margin-left: 1%;
  margin-top: 20px;
  display: flex;
}

.repayment_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 200px;
  height: 18px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  justify-content: 'space-between';
}

.repayment_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.repayment_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
  cursor: pointer;
}

.repayment_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.repayment_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.repayment_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.repayment_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.repayment_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.repayment_amount_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.repayment_dropdown {
  width: 17%;
  margin-left: 10px;
}

.repayment_search_bar_column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.repayment_bank_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 15%;
}

.typography {
  display: flex;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 50% !important;
}

.dialogText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialogTextData {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.repayment_pagination_div {
  margin-left: 1%;
  padding: 0;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  align-self: center;
  background-color: #ececec;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
