.pool_clientlist_model_div {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 12.5%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.pool_clientlist_model_title_div {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
}

.pool_clientlist_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 25px;
}

.pool_clientlist_search_bar_div {
  margin-top: 15px;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.pool_clientlist_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  width: 20%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
}

.pool_clientlist_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.pool_clientlist_table_div {
  flex: 1;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
}

.pool_clientlist_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.pool_clientlist_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}
