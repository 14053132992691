.poollist_column_container {
  width: 92%;
  display: grid;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pool_container {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  overflow: hidden;
}

.pool_top_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.pool_logo_and_title_div {
  display: flex;
  width: 12%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
}

.pool_logo_image_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  border-radius: 2px;
}

.pool_logo_image {
  width: 30px;
}

.pool_title {
  color: #323232;
  font-weight: bold;
  font-size: 15px;
  margin-left: 10px;
  text-align: center;
}

.pool_middle_container {
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
}

.pool_top_title {
  margin: 0;
  padding: 5px;
  color: #757575;
  font-weight: 400;
  font-size: 12px;
}

.pool_top_subtitle {
  margin: 0;
  padding: 5px;
  color: #323232;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.pool_riskrate_container {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pool_riskrate_container_title {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.pool_riskrate_container_text {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  margin-left: 10px;
}

.pool_bottom_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
  padding-right: 10px;
}

.pool_bottom_container_button_div {
  width: 17%;
  display: flex;
  align-self: center;
  justify-content: center;
}

.pool_bottom_container_button {
  width: 92%;
  background-color: var(--blue);
  padding: 8px;
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pool_bottom_container_button:hover {
  background-color: var(--blue-hover);
}

.pool_bottom_container_view_more_div {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.pool_view_more_text {
  margin: 0;
  padding: 0;
  color: var(--blue);
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.pool_more_details {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  margin-top: 20px;
  border-top: 1px solid #cfcfcf;
}

.pool_more_details_title {
  margin: 0;
  padding: 0;
  color: #323232;
  font-weight: 700;
  font-size: 14px;
}

.pool_more_details_subtitle {
  margin: 0;
  margin-top: 10px;
  padding: 0;
  color: #323232;
  font-weight: 300;
  font-size: 13px;
}

.pool_more_partner_title {
  margin: 0;
  padding: 0;
  color: #323232;
  font-weight: 700;
  font-size: 13px;
}

.pool_container_warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(237, 129, 52, 0.09);
  padding: 10px;
  border-radius: 3px;
}

.pool_container_warning_text {
  margin: 0;
  padding: 0;
  color: #ed8134;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}
