@import url(https://fonts.googleapis.com/css?family=Asap:300,400,500&display=swap);
:root {
  --blue: #3a86ff;
  --blue-hover: #2c74e7;
  --green: #569364;
  --green-hover: #478355;
  --red: #c94e4e;
  --orange: #fa8950;
  --invalid: #ff2e2e;
  --gray: #405983;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Asap";
}

button,
textarea,
input {
  font-family: "Asap";
}

input,
textarea:focus {
  border: 0.5px solid #3a86ff;
  border: 0.5px solid var(--blue);
}

.route_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.route_rest_container {
  display: flex;
  flex: 1 1;
  width: 100%;
  flex-direction: row;
}

.route_switch_container {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.no_row_found_div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
}

.no_row_found {
  margin: 0;
  padding: 0;
  align-self: center;
  font-size: 13px;
  color: rgb(63, 63, 63);
}

.pagination_div {
  margin: 0 0% 0 1%;
  padding: 0;
  width: 92%;
  height: 50px;
  margin-bottom: 20px;
  align-self: center;
  background-color: #ececec;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.common_header_main_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  background-color: rgb(255, 255, 255);
  border-bottom: 1.5px solid #dcdcdc;
  position: fixed;
  z-index: 200;
}

.common_header_logo {
  margin-left: 2%;
  width: 10%;
  cursor: pointer;
}

.common_header_ifa_details {
  margin-right: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.common_header_ifa_details_part {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.common_header_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #42474f;
  font-weight: 500;
}

.common_header_divider {
  height: 30px;
  width: 15px;
  border-left: 2px solid #42474f;
}

/* @media only screen and (max-width: 1000px) {
  .common_header_logo {
    width: 10%;
  }

  .common_header_text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .common_header_logo {
    width: 15%;
  }

  .common_header_text {
    font-size: 17px;
  }
}

@media only screen and (max-width: 800px) {
  .common_header_logo {
    width: 20%;
  }

  .common_header_text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .common_header_logo {
    width: 25%;
  }

  .common_header_text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .common_header_logo {
    width: 30%;
  }

  .common_header_ifa_details {
    flex-direction: column;
    align-items: flex-start;
  }

  .common_header_ifa_details_part {
    margin-top: 5px;
  }

  .common_header_text {
    font-size: 14px;
  }

  .common_header_divider {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .common_header_logo {
    width: 35%;
  }

  .common_header_ifa_details {
    flex-direction: column;
    align-items: flex-start;
  }

  .common_header_ifa_details_part {
    margin-top: 5px;
  }

  .common_header_text {
    font-size: 13px;
  }

  .common_header_divider {
    display: none;
  }
} */

.common_sidebar_main_container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 220px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-right: 1.5px solid #dcdcdc;
  z-index: 100;
}

.common_sidebar_menu_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 120px;
}

.common_sidebar_menu_item_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.common_sidebar_menu_text_div {
  display: flex;
  width: 100%;
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}

.common_sidebar_menu_text {
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-left: 50px;
  font-weight: 500;
}

.common_sidebar_menu_text_div:hover {
  background-color: rgb(247, 247, 247);
}

.common_sidebar_menu_logout_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
}

.common_sidebar_menu_logout_button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  width: 40%;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.common_sidebar_menu_logout_button_container:hover {
  background-color: var(--blue-hover);
}

.common_sidebar_menu_logout_text {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
}
/* 
@media only screen and (max-width: 1000px) {
  .common_sidebar_main_container {
    width: 220px;
  }

  .common_sidebar_menu_text {
    margin-left: 50px;
    font-size: 14px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 40%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  .common_sidebar_main_container {
    width: 180px;
  }

  .common_sidebar_menu_text {
    margin-left: 40px;
    font-size: 13px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 45%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 800px) {
  .common_sidebar_main_container {
    width: 160px;
  }

  .common_sidebar_menu_text {
    margin-left: 30px;
    font-size: 12px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 50%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 700px) {
  .common_sidebar_main_container {
    width: 140px;
  }

  .common_sidebar_menu_text {
    margin-left: 20px;
    font-size: 11px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 55%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 11px;
  }
} */

.auth_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.auth_header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.auth_logo_image {
  width: 13%;
  cursor: pointer;
}

.auth_rest_container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  margin-top: 95px;
}

.auth_image_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  background-color: #f2f7ff;
}

.auth_main_title {
  color: #414141;
  font-weight: bolder;
  font-size: 40px;
  margin-top: 100px;
  margin-bottom: 80px;
}

.auth_image {
  width: 70%;
  margin-right: 100px;
}

.auth_form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding-bottom: 20px;
}

.main_login {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.login_container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 40px;
  padding-right: 40px;
  width: 60%;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
}

.login_header {
  display: flex;
  width: 100%;
  align-items: center;
}

.login_header_title {
  margin: 0;
  padding: 0;
  color: #535252;
  font-weight: bold;
  font-size: 32px;
}

.login_form_container {
  width: 100%;
  margin-top: 20px;
}

.login_field_container {
  display: flex;
  flex-direction: column;
}

.login_label_container {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #272727;
}

.login_input_container {
  margin-top: 10px;
  padding: 12px;
  font-size: large;
  border: 1px solid #494949;
  border-radius: 3px;
}

.login_input_container:disabled {
  background-color: rgb(247, 247, 247);
}

.login_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.login_footer {
  display: flex;
  margin-top: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.login_footer_text {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #6b6b6b;
  font-weight: 500;
}

.login_footer_text_link {
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-size: 18px;
  color: var(--blue);
  font-weight: 500;
  cursor: pointer;
}

.login_notice_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  width: 60%;
  border-radius: 3px;
}

.login_notice_text {
  font-size: 16px;
}

.login_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}

.overview_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.overview_rest_div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.overview_rest_div_heading {
  margin: 0;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.overview_border {
  background: linear-gradient(to bottom, #f6f6f6 80px, white 0%);
  box-sizing: border-box;
  border: 1px solid #b0b5b1;
  border-radius: 10px;
  padding: 30px 20px;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.overview_client_heading {
  margin: 2%;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.overview_column_container {
  margin: 2%;
  width: 92%;
  align-self: center;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-bottom: 6%;
}

.overview_row_container {
  display: flex;
  height: 100px;
  margin-top: 20px;
}

.overview_row_container_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
  padding-left: 15px;
  border-radius: 3px;
  border: 1px #d0d0d0;
}

.overview_row_container_title {
  margin: 13px 0px 0px 0px;
  padding: 0;
  color: #262626;
  font-weight: 500;
  font-size: 14px;
}

.overview_tooltip_grid {
  display: grid;
  grid-template-columns: 90% 5%;
  margin:0;
  padding:0;
}

.overview_row_container_subtitle {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: #262626;
  font-weight: bolder;
  font-size: 28px;
}

.overview_row_container_highlight {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: var(--blue);
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.overview-model-div {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 12.5%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.overview-model-header-div {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 29px;
  padding-right: 29px;
}

.overview-model-header-text {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
}

.overview-model-footer-div {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-model-footer-button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 200px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
}

.overview-model-footer-button:hover {
  background-color: var(--green);
  color: #fff;
}

.overview_logout_icon {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  padding-right: 30px;
}

.overview_pagination_div {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  width: 95%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
  background-color: #ececec;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.client_notify {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.client_notify:hover {
  background-color: var(--green);
  color: #fff;
  cursor: pointer;
}

.overview-model-div-aum {
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: auto;
  margin-top: 15%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.overview-model-header-div-aum {
  padding-top: 22px;
  display: flex;
  justify-content: space-between;
  padding-left: 29px;
  border-radius: 4px 4px 0px 0px;
  background: #f2f2f2;
  padding-right: 29px;
  padding-bottom: 22px;
}
.overview-model-header-text-aum {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #343434;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.overview-tab-container {
  padding: 10px;
  margin-left: 18px;
  margin-top: 15px;
  padding-right: 27px;
}
.overview-tab-navbar {
  display: flex;
}

.overview_tab {
  background: #f3f3f3;
  color: #686868;
  font-size: 12px;
  font-style: normal;
  padding: 9px;
  text-align: center;
  font-weight: 400;
  width: 137px;
  cursor: pointer;
  line-height: normal;
}

.overview_tab_selected {
  background: #3a86ff;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  padding: 9px;
  font-weight: 400;
  text-align: center;
  width: 137px;
  cursor: pointer;
  line-height: normal;
}
.overview_tab_panel_container {
  border-radius: 4px 4px 0px 0px;
  background: #fbfbfb;
  margin-top: 15px;
}

.overview_total_invested_amount {
  padding: 10px 0px 5px 15px;
  display: flex;
}

.overview_total_invested_amount_text {
    color: #3c3c3c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 3px;
    margin-right: 5px;
}

.overview_total_invested_amount_amount {
  color: #424242;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.overview_aum_split_container {
  display: flex;
  padding: 10px;
}

.overview_aum_invested_plan {
  padding: 10px 10px 10px 5px;
  display: grid;
  grid-gap: 5px;
}

.overview_aum_invested_plan_2 {
  padding: 10px 10px 10px 5px;
  display: grid;
  grid-gap: 5px;
  margin-left: 15%
}

.overview_aum_invested_plan_text {
  color: #3c3c3c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.overview_aum_invested_plan_amount {
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.overview-model-footer-button-aum {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 5px;
  width: 90px;
  border: 1.5px solid #3a86ff;
  background-color: #fff;
  color: #3a86ff;
  font-size: 12px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
}

.overview-model-footer-div-aum {
  margin-bottom: 20px;
  display: flex;
  justify-content: right;
  padding-right: 25px;
  padding-top: 10px;
  
}
/* 
@media only screen and (max-width: 1000px) {
  .overview_rest_div_heading {
    font-size: 30px;
  }
  .overview_column_container {
    grid-template-columns: 50% 50%;
  }
  .overview_row_container_title {
    font-size: 13px;
  }
  .overview_row_container_subtitle {
    font-size: 36px;
  }
  .overview_row_container_highlight {
    font-size: 13px;
  }
}

@media only screen and (max-width: 700px) {
  .overview_rest_div_heading {
    font-size: 26px;
  }
  .overview_column_container {
    grid-template-columns: 100%;
  }
  .overview_row_container_title {
    font-size: 12px;
  }
  .overview_row_container_subtitle {
    font-size: 34px;
  }
  .overview_row_container_highlight {
    font-size: 12px;
  }
} */

.investmentHistory_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.investmentHistory_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.investmentHistory_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.investmentHistory_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.investmentHistory_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  height: 40px;
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between';
}

.investmentHistory_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  height: 18px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  width: 200px;
}

.investmentHistory_dropdown {
  width: 200px;
  margin-left: 10px;
}

.investmentHistory_search_bar_column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left:4%;
  margin-right:4%;
}

.investmentHistory_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.investmentHistory_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
  cursor: pointer;
}

.investmentHistory_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.investmentHistory_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.investmentHistory_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.investmentHistory_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.investmentHistory_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.receive_amount_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.investor_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.investor_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.investor_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.investor_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.investor_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
}

.investor_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 200px;
  background-color: 'red';
  height: 18px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.investor_dropdown {
  width: 17%;
  margin-left: 10px;
}

.investor_search_bar_column {
  display: flex;
  flex-direction: column;
}

.investor_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.investor_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
  cursor: pointer;
}

.investor_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.investor_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.investor_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.investor_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.investor_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.receive_amount_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.profile_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}
.profile_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 240px;
  margin-right: 20px;
  margin-bottom: 50px;
}

.profile_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profile_header_div_title {
  margin-left: 40%;
  padding: 0;

  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.profile_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.profile_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 15%;
  height: 18px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.profile_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.profile_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
  cursor: pointer;
}

.profile_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.profile_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.profile_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.profile_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.profile_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.receive_amount_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.document_link {
  margin-top: -10px;
  margin-left: 10px;
  color: blue;
  cursor: pointer;
}

.link {
  text-decoration: underline;
}

.action_buttons {
  margin-bottom: 20px;
}
.button {
  width: 250px;
}

.repayment_div_container {
  display: flex;
  flex-direction: column;
  height: 92%;
  margin-right:3%;
}

.repayment_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 0px;
}

.repayment_header_div {
  width: 100%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.repayment_header_div_title {
  margin: 0;
  padding: 0;
  margin-left: 1%;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.repayment_search_bar_div {
  width: 100%;
  align-self: left;
  margin-left: 1%;
  margin-top: 20px;
  display: flex;
}

.repayment_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 200px;
  height: 18px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  justify-content: 'space-between';
}

.repayment_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.repayment_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
  cursor: pointer;
}

.repayment_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.repayment_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.repayment_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.repayment_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.repayment_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.repayment_amount_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.repayment_dropdown {
  width: 17%;
  margin-left: 10px;
}

.repayment_search_bar_column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.repayment_bank_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 15%;
}

.typography {
  display: flex;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 50% !important;
}

.dialogText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialogTextData {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.repayment_pagination_div {
  margin-left: 1%;
  padding: 0;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  align-self: center;
  background-color: #ececec;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagenotfound_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pagenotfound_rest_container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
  align-items: center;
}

.pagenotfound_container_title {
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 100px;
  letter-spacing: 10px;
  color: var(--blue);
  text-align: center;
}

.pagenotfound_container_subtitle {
  margin-top: 10px;
  font-size: 25px;
  color: var(--gray);
  text-align: center;
}

.commission_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.commission_div_tablecontainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.commission_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.commission_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.commission_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
}

.commission_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 200px;
  background-color: 'red';
  height: 18px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.commission_dropdown {
  width: 17%;
  margin-left: 10px;
}

.commission_search_bar_column {
  display: flex;
  flex-direction: column;
}

.commission_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.commission_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
  cursor: pointer;
}

.commission_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.commission_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.commission_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.commission_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.commission_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.receive_amount_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.commission-action-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commission-action-button-div-button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 150px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
  font-size:12px
}


.commission_investment-model-div {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-left: 12.5%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  
  .commission_investment-model-header-div {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 29px;
  }
  
  .commission_investment-model-header-text {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
  }
  
  .commission_investment-model-footer-div {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .commission_investment-model-footer-button {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 3px;
    width: 200px;
    border: 1px solid var(--green);
    background-color: #fff;
    color: var(--green);
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .commission_investment-model-footer-button:hover {
    background-color: var(--green);
    color: #fff;
  }
  
  .commission_investment_logout_icon {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    padding-right: 30px;
  }
  
  .commission_investment_pagination_div {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    width: 92%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: center;
    background-color: #ececec;
    border-left: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media only screen and (max-width: 1300px) {
    .commission_investment-model-div {
        width: 82%;
    }
  }

  @media only screen and (max-width: 1200px) {
    .commission_investment-model-div {
        display: flex;
        flex-direction: column;
        width: 85%;
    }
  }
.poollist_column_container {
  width: 92%;
  display: grid;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pool_container {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  overflow: hidden;
}

.pool_top_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.pool_logo_and_title_div {
  display: flex;
  width: 12%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
}

.pool_logo_image_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 30px;
  border-radius: 2px;
}

.pool_logo_image {
  width: 30px;
}

.pool_title {
  color: #323232;
  font-weight: bold;
  font-size: 15px;
  margin-left: 10px;
  text-align: center;
}

.pool_middle_container {
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
}

.pool_top_title {
  margin: 0;
  padding: 5px;
  color: #757575;
  font-weight: 400;
  font-size: 12px;
}

.pool_top_subtitle {
  margin: 0;
  padding: 5px;
  color: #323232;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.pool_riskrate_container {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pool_riskrate_container_title {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.pool_riskrate_container_text {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  margin-left: 10px;
}

.pool_bottom_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
  padding-right: 10px;
}

.pool_bottom_container_button_div {
  width: 17%;
  display: flex;
  align-self: center;
  justify-content: center;
}

.pool_bottom_container_button {
  width: 92%;
  background-color: var(--blue);
  padding: 8px;
  color: #fff;
  font-size: large;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

.pool_bottom_container_button:hover {
  background-color: var(--blue-hover);
}

.pool_bottom_container_view_more_div {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.pool_view_more_text {
  margin: 0;
  padding: 0;
  color: var(--blue);
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.pool_more_details {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  margin-top: 20px;
  border-top: 1px solid #cfcfcf;
}

.pool_more_details_title {
  margin: 0;
  padding: 0;
  color: #323232;
  font-weight: 700;
  font-size: 14px;
}

.pool_more_details_subtitle {
  margin: 0;
  margin-top: 10px;
  padding: 0;
  color: #323232;
  font-weight: 300;
  font-size: 13px;
}

.pool_more_partner_title {
  margin: 0;
  padding: 0;
  color: #323232;
  font-weight: 700;
  font-size: 13px;
}

.pool_container_warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(237, 129, 52, 0.09);
  padding: 10px;
  border-radius: 3px;
}

.pool_container_warning_text {
  margin: 0;
  padding: 0;
  color: #ed8134;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}


.poollist_model_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  
  .poollist_model_title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .poollist_model_title {
    margin: 0;
    padding: 0;
    color: #414141;
    font-weight: bold;
    font-size: 18px;
  }
  
  .poollist_model_input {
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    font-size: large;
    border-radius: 3px;
    border: 1px solid rgb(73, 73, 73);
  }
  
  .poollist_model_button {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .poollist_model_button:hover {
    background-color: var(--blue-hover);
  }
  
.pool_clientlist_model_div {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 12.5%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.pool_clientlist_model_title_div {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
}

.pool_clientlist_model_title {
  margin: 0;
  padding: 0;
  color: #414141;
  font-weight: bold;
  font-size: 25px;
}

.pool_clientlist_search_bar_div {
  margin-top: 15px;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.pool_clientlist_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  width: 20%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
}

.pool_clientlist_search_bar_input {
  flex: 1 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.pool_clientlist_table_div {
  flex: 1 1;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 5px;
}

.pool_clientlist_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.pool_clientlist_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

