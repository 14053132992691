
.poollist_model_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  
  .poollist_model_title_div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .poollist_model_title {
    margin: 0;
    padding: 0;
    color: #414141;
    font-weight: bold;
    font-size: 18px;
  }
  
  .poollist_model_input {
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    font-size: large;
    border-radius: 3px;
    border: 1px solid rgb(73, 73, 73);
  }
  
  .poollist_model_button {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
    background-color: var(--blue);
    color: #fff;
    font-size: large;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .poollist_model_button:hover {
    background-color: var(--blue-hover);
  }
  