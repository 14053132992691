.commission_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.commission_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.commission_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.commission_header_div_title {
  margin: 0;
  padding: 0;
  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.commission_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
}

.commission_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 200px;
  background-color: 'red';
  height: 18px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.commission_dropdown {
  width: 17%;
  margin-left: 10px;
}

.commission_search_bar_column {
  display: flex;
  flex-direction: column;
}

.commission_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.commission_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
  cursor: pointer;
}

.commission_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.commission_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.commission_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.commission_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.commission_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.receive_amount_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.commission-action-button-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commission-action-button-div-button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 150px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
  font-size:12px
}

