.commission_investment-model-div {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-left: 12.5%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
  }
  
  .commission_investment-model-header-div {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 29px;
  }
  
  .commission_investment-model-header-text {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
  }
  
  .commission_investment-model-footer-div {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .commission_investment-model-footer-button {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 3px;
    width: 200px;
    border: 1px solid var(--green);
    background-color: #fff;
    color: var(--green);
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .commission_investment-model-footer-button:hover {
    background-color: var(--green);
    color: #fff;
  }
  
  .commission_investment_logout_icon {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    padding-right: 30px;
  }
  
  .commission_investment_pagination_div {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    width: 92%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: center;
    background-color: #ececec;
    border-left: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media only screen and (max-width: 1300px) {
    .commission_investment-model-div {
        width: 82%;
    }
  }

  @media only screen and (max-width: 1200px) {
    .commission_investment-model-div {
        display: flex;
        flex-direction: column;
        width: 85%;
    }
  }