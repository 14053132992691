.common_header_main_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  background-color: rgb(255, 255, 255);
  border-bottom: 1.5px solid #dcdcdc;
  position: fixed;
  z-index: 200;
}

.common_header_logo {
  margin-left: 2%;
  width: 10%;
  cursor: pointer;
}

.common_header_ifa_details {
  margin-right: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.common_header_ifa_details_part {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.common_header_text {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #42474f;
  font-weight: 500;
}

.common_header_divider {
  height: 30px;
  width: 15px;
  border-left: 2px solid #42474f;
}

/* @media only screen and (max-width: 1000px) {
  .common_header_logo {
    width: 10%;
  }

  .common_header_text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .common_header_logo {
    width: 15%;
  }

  .common_header_text {
    font-size: 17px;
  }
}

@media only screen and (max-width: 800px) {
  .common_header_logo {
    width: 20%;
  }

  .common_header_text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .common_header_logo {
    width: 25%;
  }

  .common_header_text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .common_header_logo {
    width: 30%;
  }

  .common_header_ifa_details {
    flex-direction: column;
    align-items: flex-start;
  }

  .common_header_ifa_details_part {
    margin-top: 5px;
  }

  .common_header_text {
    font-size: 14px;
  }

  .common_header_divider {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .common_header_logo {
    width: 35%;
  }

  .common_header_ifa_details {
    flex-direction: column;
    align-items: flex-start;
  }

  .common_header_ifa_details_part {
    margin-top: 5px;
  }

  .common_header_text {
    font-size: 13px;
  }

  .common_header_divider {
    display: none;
  }
} */
