.main_login {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.login_container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 40px;
  padding-right: 40px;
  width: 60%;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
}

.login_header {
  display: flex;
  width: 100%;
  align-items: center;
}

.login_header_title {
  margin: 0;
  padding: 0;
  color: #535252;
  font-weight: bold;
  font-size: 32px;
}

.login_form_container {
  width: 100%;
  margin-top: 20px;
}

.login_field_container {
  display: flex;
  flex-direction: column;
}

.login_label_container {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  color: #272727;
}

.login_input_container {
  margin-top: 10px;
  padding: 12px;
  font-size: large;
  border: 1px solid #494949;
  border-radius: 3px;
}

.login_input_container:disabled {
  background-color: rgb(247, 247, 247);
}

.login_submit_container {
  background-color: var(--blue);
  color: #fff;
  margin-top: 12px;
  padding: 12px;
  border: none;
  width: 100%;
  font-size: 18px;
  border-radius: 3px;
  outline: none;
}

.login_footer {
  display: flex;
  margin-top: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.login_footer_text {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #6b6b6b;
  font-weight: 500;
}

.login_footer_text_link {
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-size: 18px;
  color: var(--blue);
  font-weight: 500;
  cursor: pointer;
}

.login_notice_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  width: 60%;
  border-radius: 3px;
}

.login_notice_text {
  font-size: 16px;
}

.login_invalid_div {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login_invalid_text {
  margin: 0;
  padding: 0;
  font-size: medium;
  color: var(--invalid);
}
