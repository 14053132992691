.overview_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.overview_rest_div {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}

.overview_rest_div_heading {
  margin: 0;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.overview_border {
  background: linear-gradient(to bottom, #f6f6f6 80px, white 0%);
  box-sizing: border-box;
  border: 1px solid #b0b5b1;
  border-radius: 10px;
  padding: 30px 20px;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.overview_client_heading {
  margin: 2%;
  padding: 0;
  width: 92%;
  margin-top: 20px;
  align-self: center;
  color: #42474f;
  font-weight: 800;
  font-size: 28px;
}

.overview_column_container {
  margin: 2%;
  width: 92%;
  align-self: center;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-bottom: 6%;
}

.overview_row_container {
  display: flex;
  height: 100px;
  margin-top: 20px;
}

.overview_row_container_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
  padding-left: 15px;
  border-radius: 3px;
  border: 1px #d0d0d0;
}

.overview_row_container_title {
  margin: 13px 0px 0px 0px;
  padding: 0;
  color: #262626;
  font-weight: 500;
  font-size: 14px;
}

.overview_tooltip_grid {
  display: grid;
  grid-template-columns: 90% 5%;
  margin:0;
  padding:0;
}

.overview_row_container_subtitle {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: #262626;
  font-weight: bolder;
  font-size: 28px;
}

.overview_row_container_highlight {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: var(--blue);
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.overview-model-div {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 12.5%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.overview-model-header-div {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 29px;
  padding-right: 29px;
}

.overview-model-header-text {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
}

.overview-model-footer-div {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-model-footer-button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 200px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
}

.overview-model-footer-button:hover {
  background-color: var(--green);
  color: #fff;
}

.overview_logout_icon {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  padding-right: 30px;
}

.overview_pagination_div {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  width: 95%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
  background-color: #ececec;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.client_notify {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.client_notify:hover {
  background-color: var(--green);
  color: #fff;
  cursor: pointer;
}

.overview-model-div-aum {
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: auto;
  margin-top: 15%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
}

.overview-model-header-div-aum {
  padding-top: 22px;
  display: flex;
  justify-content: space-between;
  padding-left: 29px;
  border-radius: 4px 4px 0px 0px;
  background: #f2f2f2;
  padding-right: 29px;
  padding-bottom: 22px;
}
.overview-model-header-text-aum {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #343434;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.overview-tab-container {
  padding: 10px;
  margin-left: 18px;
  margin-top: 15px;
  padding-right: 27px;
}
.overview-tab-navbar {
  display: flex;
}

.overview_tab {
  background: #f3f3f3;
  color: #686868;
  font-size: 12px;
  font-style: normal;
  padding: 9px;
  text-align: center;
  font-weight: 400;
  width: 137px;
  cursor: pointer;
  line-height: normal;
}

.overview_tab_selected {
  background: #3a86ff;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  padding: 9px;
  font-weight: 400;
  text-align: center;
  width: 137px;
  cursor: pointer;
  line-height: normal;
}
.overview_tab_panel_container {
  border-radius: 4px 4px 0px 0px;
  background: #fbfbfb;
  margin-top: 15px;
}

.overview_total_invested_amount {
  padding: 10px 0px 5px 15px;
  display: flex;
}

.overview_total_invested_amount_text {
    color: #3c3c3c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 3px;
    margin-right: 5px;
}

.overview_total_invested_amount_amount {
  color: #424242;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.overview_aum_split_container {
  display: flex;
  padding: 10px;
}

.overview_aum_invested_plan {
  padding: 10px 10px 10px 5px;
  display: grid;
  grid-gap: 5px;
}

.overview_aum_invested_plan_2 {
  padding: 10px 10px 10px 5px;
  display: grid;
  grid-gap: 5px;
  margin-left: 15%
}

.overview_aum_invested_plan_text {
  color: #3c3c3c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.overview_aum_invested_plan_amount {
  color: #424242;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.overview-model-footer-button-aum {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 5px;
  width: 90px;
  border: 1.5px solid #3a86ff;
  background-color: #fff;
  color: #3a86ff;
  font-size: 12px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
}

.overview-model-footer-div-aum {
  margin-bottom: 20px;
  display: flex;
  justify-content: right;
  padding-right: 25px;
  padding-top: 10px;
  
}
/* 
@media only screen and (max-width: 1000px) {
  .overview_rest_div_heading {
    font-size: 30px;
  }
  .overview_column_container {
    grid-template-columns: 50% 50%;
  }
  .overview_row_container_title {
    font-size: 13px;
  }
  .overview_row_container_subtitle {
    font-size: 36px;
  }
  .overview_row_container_highlight {
    font-size: 13px;
  }
}

@media only screen and (max-width: 700px) {
  .overview_rest_div_heading {
    font-size: 26px;
  }
  .overview_column_container {
    grid-template-columns: 100%;
  }
  .overview_row_container_title {
    font-size: 12px;
  }
  .overview_row_container_subtitle {
    font-size: 34px;
  }
  .overview_row_container_highlight {
    font-size: 12px;
  }
} */
