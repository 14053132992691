@import url("https://fonts.googleapis.com/css?family=Asap:300,400,500&display=swap");

:root {
  --blue: #3a86ff;
  --blue-hover: #2c74e7;
  --green: #569364;
  --green-hover: #478355;
  --red: #c94e4e;
  --orange: #fa8950;
  --invalid: #ff2e2e;
  --gray: #405983;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Asap";
}

button,
textarea,
input {
  font-family: "Asap";
}

input,
textarea:focus {
  border: 0.5px solid var(--blue);
}

.route_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.route_rest_container {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
}

.route_switch_container {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.no_row_found_div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
}

.no_row_found {
  margin: 0;
  padding: 0;
  align-self: center;
  font-size: 13px;
  color: rgb(63, 63, 63);
}

.pagination_div {
  margin: 0 0% 0 1%;
  padding: 0;
  width: 92%;
  height: 50px;
  margin-bottom: 20px;
  align-self: center;
  background-color: #ececec;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
