.profile_div_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 220px;
}
.profile_div_tablecontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 240px;
  margin-right: 20px;
  margin-bottom: 50px;
}

.profile_header_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profile_header_div_title {
  margin-left: 40%;
  padding: 0;

  color: #42474f;
  font-weight: 600;
  font-size: 28px;
}

.profile_search_bar_div {
  width: 92%;
  align-self: center;
  margin-top: 20px;
}

.profile_search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 15%;
  height: 18px;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  overflow: hidden;
}

.profile_search_bar_input {
  flex: 1;
  font-size: 12px;
  border: none;
  outline: none;
  color: #555555;
}

.profile_client_name {
  margin: 0;
  padding: 0;
  color: var(--blue);
  cursor: pointer;
}

.profile_action_button {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--orange);
  background-color: #fff;
  color: var(--orange);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.profile_action_button_invest {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--green);
  background-color: #fff;
  color: var(--green);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.profile_action_button_invest:hover {
  background-color: var(--green);
  color: #fff;
}

.profile_action_button_fix_kyc {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 3px;
  width: 130px;
  border: 1px solid var(--red);
  background-color: #fff;
  color: var(--red);
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.profile_action_button_fix_kyc:hover {
  background-color: var(--red);
  color: #fff;
}

.receive_amount_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.document_link {
  margin-top: -10px;
  margin-left: 10px;
  color: blue;
  cursor: pointer;
}

.link {
  text-decoration: underline;
}

.action_buttons {
  margin-bottom: 20px;
}
.button {
  width: 250px;
}
