.common_sidebar_main_container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 220px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-right: 1.5px solid #dcdcdc;
  z-index: 100;
}

.common_sidebar_menu_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 120px;
}

.common_sidebar_menu_item_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.common_sidebar_menu_text_div {
  display: flex;
  width: 100%;
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}

.common_sidebar_menu_text {
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-left: 50px;
  font-weight: 500;
}

.common_sidebar_menu_text_div:hover {
  background-color: rgb(247, 247, 247);
}

.common_sidebar_menu_logout_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
}

.common_sidebar_menu_logout_button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  width: 40%;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.common_sidebar_menu_logout_button_container:hover {
  background-color: var(--blue-hover);
}

.common_sidebar_menu_logout_text {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: white;
}
/* 
@media only screen and (max-width: 1000px) {
  .common_sidebar_main_container {
    width: 220px;
  }

  .common_sidebar_menu_text {
    margin-left: 50px;
    font-size: 14px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 40%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  .common_sidebar_main_container {
    width: 180px;
  }

  .common_sidebar_menu_text {
    margin-left: 40px;
    font-size: 13px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 45%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 800px) {
  .common_sidebar_main_container {
    width: 160px;
  }

  .common_sidebar_menu_text {
    margin-left: 30px;
    font-size: 12px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 50%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 700px) {
  .common_sidebar_main_container {
    width: 140px;
  }

  .common_sidebar_menu_text {
    margin-left: 20px;
    font-size: 11px;
  }

  .common_sidebar_menu_logout_button_container {
    width: 55%;
  }

  .common_sidebar_menu_logout_text {
    font-size: 11px;
  }
} */
