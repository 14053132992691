.auth_main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

.auth_header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: rgba(211, 211, 211, 0.336) 0px 10px 20px 0px;
}

.auth_logo_image {
  width: 13%;
  cursor: pointer;
}

.auth_rest_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 95px;
}

.auth_image_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  background-color: #f2f7ff;
}

.auth_main_title {
  color: #414141;
  font-weight: bolder;
  font-size: 40px;
  margin-top: 100px;
  margin-bottom: 80px;
}

.auth_image {
  width: 70%;
  margin-right: 100px;
}

.auth_form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding-bottom: 20px;
}
